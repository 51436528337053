"use client";

import Link from "next/link";

import { Button } from "@/components/ui/button";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import useGTMEvent from "@/hooks/useGTM";

export default function NotFound() {
  const { user: currentUser } = useCurrentUser();
  const sendGTMEvent = useGTMEvent();

  return (
    <section className="grid place-content-center h-screen gap-8">
      <h1 className="text-2xl font-semibold tracking-tighter">
        Oups, il semble que vous soyez perdu...
      </h1>
      <p>Cette page n&apos;existe pas ou a été supprimée.</p>

      <Button
        variant="secondary"
        className="font-bold group/arrow"
        onClick={() =>
          sendGTMEvent("ci_event", {
            category: "navigation",
            value: "back_to_dashboard",
          })
        }
      >
        {currentUser ? (
          <Link aria-label="Aller au tableau de bord" href={"/app/dashboard"}>
            Aller au tableau de bord
          </Link>
        ) : (
          <Link aria-label="Retourner à la page d'accueil" href={"/"}>
            Retourner à la page d&apos;accueil
          </Link>
        )}
      </Button>
    </section>
  );
}
