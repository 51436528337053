const useGTMEvent = () => {
  const sendEvent = (
    eventName: string,
    eventParams: Record<string, any> = {}
  ) => {
    if (typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({
        event: eventName,
        ...eventParams,
      });
    }
  };

  return sendEvent;
};

export default useGTMEvent;
