import Cookies from "js-cookie";

export const getHeaders = ({ auth = false, contentType = true } = {}) => {
  let headers: any = {};

  if (contentType) {
    headers["Content-Type"] = "application/json";
  }

  if (auth) {
    const currentUser = Cookies.get("currentUser");
    if (currentUser) {
      headers.Authorization = `Bearer ${
        JSON.parse(currentUser || "")?.accessToken || ""
      }`;
    }
  }

  return headers;
};
