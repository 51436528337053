import { getHeaders } from "./headers";

export const checkSignUpToken = async (token: string) => {
  return await fetch(`/api/auth/signup/check`, {
    method: "POST",
    body: JSON.stringify({
      token,
    }),
    headers: getHeaders({ auth: false }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data: any) => {
      if (data.error) {
        throw new Error(data.error);
      }

      return data;
    });
};

export const signUp = async (email: string) => {
  return await fetch(`/api/auth/signup`, {
    method: "POST",
    body: JSON.stringify({
      email,
    }),
    headers: getHeaders({ auth: false }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data: any) => {
      if (data.error) {
        throw new Error(data.error);
      }

      return data.email;
    });
};

export const confirmSignUp = async (token: string, password: string) => {
  return await fetch(`/api/auth/signup/confirm`, {
    method: "POST",
    body: JSON.stringify({
      token,
      password,
    }),
    headers: getHeaders({ auth: false }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data: any) => {
      if (data.error) {
        throw new Error(data.error);
      }

      return {
        email: data.user.email,
        avatar: data.user.avatar,
        id: data.user.id,
        accessToken: data.user.access_token,
        expiredAt: data.user.expiredAt,
      };
    });
};

export const login = async (email: string, password: string) => {
  return await fetch(`/api/auth/login`, {
    method: "POST",
    body: JSON.stringify({
      email,
      password,
    }),
    headers: getHeaders({ auth: false }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data: any) => {
      if (data.error) {
        throw new Error(data.error);
      }

      return {
        email: data.user.email,
        avatar: data.user.avatar,
        id: data.user.id,
        accessToken: data.user.access_token,
        expiredAt: data.user.expiredAt,
      };
    });
};

export const resetPassword = async (email: string) => {
  return await fetch(`/api/auth/reset-password`, {
    method: "POST",
    body: JSON.stringify({
      email,
    }),
    headers: getHeaders({ auth: false }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data: any) => {
      if (data.error) {
        throw new Error(data.error);
      }

      return data.email;
    });
};

export const confirmResetPassword = async (token: string, password: string) => {
  return await fetch(`/api/auth/reset-password/confirm`, {
    method: "POST",
    body: JSON.stringify({
      token,
      password,
    }),
    headers: getHeaders({ auth: false }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data: any) => {
      if (data.error) {
        throw new Error(data.error);
      }

      return {
        email: data.user.email,
        avatar: data.user.avatar,
        id: data.user.id,
        accessToken: data.user.access_token,
        expiredAt: data.user.expiredAt,
      };
    });
};

export const checkResetPasswordToken = async (token: string) => {
  return await fetch(`/api/auth/reset-password/check`, {
    method: "POST",
    body: JSON.stringify({
      token,
    }),
    headers: getHeaders({ auth: false }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data: any) => {
      if (data.error) {
        throw new Error(data.error);
      }

      return data;
    });
};

export const getMe = async (userId: string) => {
  return await fetch(`/api/users/${userId}`, {
    method: "GET",
    headers: getHeaders({ auth: true }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data.user;
    })
    .catch((error) => {
      throw error;
    });
};

export const uploadAvatar = async (userId: string, newAvatar: File) => {
  const formData = new FormData();
  formData.append("file", newAvatar);

  return await fetch(`/users/${userId}/upload`, {
    method: "POST",
    body: formData,
    headers: getHeaders({ auth: true }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return {
        newAvatar: data.avatar.url,
      };
    })
    .catch((error) => {
      console.error("There was a problem with the fetch operation:", error);
      throw error;
    });
};
